<template>
    <div>
        <modal :name="modal_name" class="final-modal increase-width-popups-variants-addons popup-modal-all-width-height" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title text-white">Product Info</span>
                        <span @click="hideProductInfoPopup()">
                        <i class="icon icon-close pointer text-white"></i>
                        </span>
                </div>
                <div class="general-section mod-popup" style="padding: 15px 0px;">
                    <div v-if="api_call_in_progress">
                        <div class="LoaderPlacement">
                            <div class="digi-table-loader inverse">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="">
                        <div class="d-flex flex-column flex-root">
                            <div class="d-flex flex-column flex-lg-row flex-column-fluid">
                                <div class="info-container container-xxl w-100">
                                    <div class="infocard mb-6">
                                        <div class="infocard-body pt-9 pb-0">
                                            <div class="d-flex flex-wrap flex-sm-nowrap">
                                                <div class="me-7 mb-4">
                                                    <div class="prodImage prodImage-100px prodImage-lg-160px prodImage-fixed position-relative">
                                                        <img :src="product_details.image || '/static/images/product_image_default.svg'" alt="image" />
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <div class="d-flex justify-content-between-center align-items-start flex-wrap mb-2">
                                                        <div class="d-flex flex-column w-80">
                                                            <div class="d-flex align-items-center mb-1">
                                                                <a href="javascript:;" class="black-text text-decoration-none fs-16 fw-600 me-1">{{product_details.name}}</a>
                                                            </div>
                                                            <div class="fw-semibold mb-4 pe-2">
                                                                <p class="fs-13 mb-1">Description</p>
                                                                <p class="fs-13 mb-1">{{product_details.description || "-"}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex w-20">
                                                            <div class="prodImage prodImage-100px prodImage-lg-160px prodImage-fixed position-relative">
                                                                <qr-code
                                                                    class="bcode"
                                                                    :text="product_details.name"
                                                                    :size="100"
                                                                    color="#005dae"
                                                                ></qr-code>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column gap-5" style="border-top: 1.5px solid #707070;">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-1 mr-2" style="border-bottom: 1px solid #707070;">
                                                            <label class="col-lg-5 px-1 fw-600 black-text mb-0">
                                                                Status
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                <span v-if="product_details.status" class="badgestatus badgestatus-success">
                                                                    Active
                                                                </span>
                                                                <span v-else class="badgestatus badgestatus-success" style="background-color: red;">
                                                                    InActive
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-2 mr-1"  style="border-bottom: 1px solid #707070;">
                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                Product Type
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center pb-1">
                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                    {{product_details.prod_type_name || "-"}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-1 mr-2" style="border-bottom: 1px solid #707070;">
                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                Category
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                    {{product_details.category_name || "-"}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-2 mr-1"  style="border-bottom: 1px solid #707070;">
                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                Brand
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                    {{product_details.brand || "-"}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-1 mr-2">
                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                Sub Category
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                    {{product_details.sub_category_name || "-"}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="row py-4 ml-2 mr-1">
                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                No. Of Variants Available
                                                            </label>
                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                    {{product_details.no_of_variants || "-"}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="product_details.variants.length" >
                                            <span class="show-variants pointer" @click="showVariants">
                                                <i v-if="show_all_variants" class="icon icon-chevron-double-down fs-20" style="color: #00448b;"></i>
                                                <i v-else class="icon icon-chevron-double-up fs-20" style="color: #00448b;"></i>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="show_all_variants && product_details.variants.length" class="infoCardVariants mb-6">
                                        <div class="infoCardVariants-body pb-0 px-0">
                                            <div class="d-flex flex-lg-row course-table cartProductRev" id="accordion_parent_variant">
                                                <div class="d-flex flex-row-fluid flex-column">
                                                    <div id="variant_accordion_parent" class="accordionBlock px-1 py-2 w-100 mb-2 w-100" v-for="(variant, var_index) in product_details.variants" :key="var_index+'var_index'">
                                                        <div class="mob-expand-collapse-variant w-100" id="variant_accordion">
                                                            <div class="d-flex align-items-center justify-content-between pointer courses-icon-text collapsed" data-toggle="collapse" :data-target="'#collapseVariants'+var_index" aria-expanded="true" :aria-controls="'collapseVariants'+var_index">
                                                                <span class="fs-14 fw-bold">
                                                                    {{variant.variant_name || "-"}}
                                                                </span>
                                                                <span class="double-arrow-top">
                                                                    <i class="icon icon-chevron-double-down fs-20 fw-bold"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="collapse px-5 variantInfo" :id="'collapseVariants'+var_index" aria-labelledby="variant_accordion" data-parent="#accordion_parent_variant">
                                                            <div class="d-flex flex-column gap-5">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="row py-4 ml-1 mr-2" style="border-bottom: 1px solid #707070;">
                                                                            <label class="col-lg-5 px-1 fw-600 black-text mb-0">
                                                                                Quantity
                                                                            </label>
                                                                            <div class="col-lg-7 px-1 d-flex align-items-center pb-1">
                                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                                    {{variant.variant_quantity || "-"}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="row py-4 ml-2 mr-1"  style="border-bottom: 1px solid #707070;">
                                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                                Product Code
                                                                            </label>
                                                                            <div class="col-lg-7 px-1 d-flex align-items-center pb-1">
                                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                                    {{variant.product_code || "-"}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="row py-4 ml-1 mr-2" style="border-bottom: 1px solid #707070;">
                                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                                Selling Price
                                                                            </label>
                                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                                    {{variant.selling_price || "-"}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="row py-4 ml-2 mr-1"  style="border-bottom: 1px solid #707070;">
                                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                                Product ID
                                                                            </label>
                                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                                    {{variant.product_id || "-"}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="row py-4 ml-1"  style="border-bottom: 1px solid #707070;">
                                                                            <label class="col-lg-5 px-1 fs-13 fw-600 black-text mb-0">
                                                                                Unit of Measure
                                                                            </label>
                                                                            <div class="col-lg-7 px-1 d-flex align-items-center">
                                                                                <span class="fw-500 fs-13 black-text me-2">
                                                                                    {{variant.uom || "-"}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="row py-1 ml-1">
                                                                            <label class="col-lg-12 px-1 fs-13 fw-600 black-text mb-2">
                                                                                Sales Location
                                                                            </label>
                                                                            <div class="col-lg-12 px-1 d-flex align-items-center pb-2">
                                                                                <span class="badgesales badgesales-primary" v-for="(sales_location, s_l_index) in variant.outlet_id" :key="s_l_index+''+var_index+'s_l_index'">
                                                                                    {{sales_location}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    export default {
        props: ['modal_name', 'product_id', 'order_id', 'is_combo'],
        data(){
            return{
                product_details: null,
                api_call_in_progress: false,
                show_all_variants: false
            }
        },
        methods:{
            hideProductInfoPopup(){
                this.$emit('hideProductInfoTakeOrder');
            },
            async getProductDetailsApi(params) {
                return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post(
                        '/mobile/get_product_details',
                        params
                    )
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                })
            },
            async fetchProductDetails() {
                this.api_call_in_progress = true;
                try {
                    let params = {
                        "_id":this.product_id,
                        "order_id":this.order_id,
                        "is_combo":this.is_combo
                    };
                    let response = await this.getProductDetailsApi(params);
                    console.log(response)
                    this.product_details = response.response;
                    this.api_call_in_progress = false;
                }
                catch(err) {
                    console.log(err)
                    this.api_call_in_progress = false;
                }
            },
            showVariants() {
                this.show_all_variants = !this.show_all_variants;
            }
        },
        mounted() {
            console.log(this.product_id)
            this.fetchProductDetails()
        }
    }
</script>

<style scoped>
    .flex-root {
        flex: 1;
    }
    .flex-column {
        flex-direction: column;
    }
    .w-150px {
        width: 150px !important;
    }
    .fs-2qx {
        font-size: 18px !important;
        padding: 2px 45px;
    }
    .flex-column-fluid {
        flex: 1 0 auto;
    }
    .flex-center {
        justify-content: center;
        align-items: center;
    }
    .mw-100 {
        max-width: 100% !important;
    }
    .black-text {
        color: #020a12;
    }
    .rounded-4 {
        border-radius: 1rem !important;
    }
    .bg-body {
        background-color: #fff !important;
    }
    .bg-body-pay {
        border-radius: 4px;
        box-shadow: 0 1.5px 6px 0 rgba(0, 0, 0, 0.47);
        background-color: #fff;
    }
    .heading {
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
        padding-bottom: 15px;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        text-transform: uppercase;
    }
    .amountDiv {
        background: #f2f2fb;
        border-radius: 2px;
        color: #00448b;
        letter-spacing: 0.18px;
        padding: 6px 8px;
        width: 135px;
        text-align: right;
        font-weight: 700 !important;
    }
    .fw-700 {
        font-weight: 700 !important;
    }
    .buttonsBlockDownloadAll {
        margin-top: 28px;
        justify-content: flex-end;
        display: flex;
    }
    .buttonsBlockDownload {
        margin-top: 25px;
        justify-content: flex-start;
        display: flex;
    }
    .btnDownloadAll {
        display:flex;
        justify-content: space-between;
        margin-bottom:0;
        font-weight:600 !important;
        text-align:center !important;
        white-space:nowrap;
        vertical-align:middle;
        -ms-touch-action:manipulation;
        touch-action:manipulation;
        cursor:pointer;
        color: #fff;
        background-color: #1b9e4c;
        border:1px solid #1b9e4c;
        padding:10px !important;
        font-size:14px !important;
        line-height:1.42857143;
        border-radius:6px;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        width: 165px;
    }
    .btnDownload {
        display:flex;
        justify-content: space-between;
        margin-bottom:0;
        font-weight:600 !important;
        text-align:center;
        white-space:nowrap;
        vertical-align:middle;
        -ms-touch-action:manipulation;
        touch-action:manipulation;
        cursor:pointer;
        color: #000;
        background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
        border: 0 !important; 
        outline: 0 none !important; 
        padding:10px !important;
        font-size:14px !important;
        line-height:1.42857143;
        border-radius:6px;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        width: 200px;
    }
    @media (min-width: 992px) {
        .flex-lg-row {
            flex-direction: row !important;
        }
        .flex-lg-row-fluid {
            flex: 1 auto;
            min-width: 0;
        }
        .pb-lg-10 {
            padding-bottom: 2.5rem !important;
        }
        .mb-lg-20 {
            margin-bottom: 5rem !important;
        }
        .w-lg-350px {
            width: 375px !important;
        }
        .flex-lg-row-auto {
            flex: 0 0 auto;
        }
        .justify-content-lg-end {
            justify-content: flex-end !important;
        }
    }
    @media (min-width: 768px) {
        .w-md-400px {
            width: 475px !important;
        }
        .w-md-380px {
            width: 400px !important;
        }
    }

    /* Info Modal */
    .info-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .show-variants {
        float: right;
        border: 1px solid #00448b;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 0px 0px 1px 2px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
    .infocard {
        border: 0;
        box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
        background-color: #eff1f4;
        border-radius: 0.625rem;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
    }
    .infocard .infocard-body {
        padding: 2rem 2.25rem;
        flex: 1 1 auto;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .me-7 {
        margin-right: 1.75rem !important;
    }
    .prodImage {
        display: inline-block;
        flex-shrink: 0;
        position: relative;
        border-radius: 0.475rem;
    }
    .position-relative {
        position: relative !important;
    }
    .prodImage > img {
        width: 100%;
        flex-shrink: 0;
        display: inline-block;
        border-radius: 0.475rem;
    }
    .prodImage.prodImage-100px.prodImage-fixed > img {
        width: 100px;
        height: 100px;
        max-width: none;
    }
    @media (min-width: 992px) {
        .prodImage.prodImage-lg-160px.prodImage-fixed > img {
            width: 160px;
            height: 160px;
            max-width: none;
        }
    }
    .flex-grow-1 {
        flex-grow: 1 !important;
    }
    .w-80 {
        width: 80%;
    }
    .w-20 {
        width: 20%;
    }
    .text-decoration-none {
        text-decoration: none;
    }
    .badgestatus {
        padding: 2px 16px !important;
        font-size: 13px;
        border-radius: 6px;
        color: white;
        text-decoration: none;
        font-weight: 600;
    }
    .badgesales {
        padding: 4px 16px !important;
        font-size: 13px;
        border-radius: 6px;
        color: white;
        text-decoration: none;
        font-weight: 600;
        margin-right: 3px;
    }
    .badgestatus-success {
        color: #fff;
        background-color: #1b9e4c;
    }
    .badgesales-primary {
        color: #fff;
        background-color: #0d84f2;
    }
    .fw-500 {
        font-weight: 500;
    }
	.mob-expand-collapse-categories {
		padding: 4px 8.5px 4px 10px;
		border-radius: 4px;
		background-color: #ffea92;
		color: #000;
		display: none;
	}
	.mob-expand-collapse-products,
	.mob-expand-collapse-variant {
		padding: 4px 8.5px 4px 10px;
		border-radius: 4px;
		background-color: #ffea92;
		color: #000;
		display: block;
	}
	.mob-expand-collapse-cart .cart-icon-text .double-arrow-top,
	.mob-expand-collapse-categories .categories-icon-text .double-arrow-top,
	.mob-expand-collapse-products .products-icon-text .double-arrow-top,
	.mob-expand-collapse-variant .courses-icon-text .double-arrow-top {
		transform: rotate(180deg);
		transition: all 0.3s;
	}
	.mob-expand-collapse-cart .cart-icon-text.collapsed .double-arrow-top, 
	.mob-expand-collapse-categories .categories-icon-text.collapsed .double-arrow-top,
	.mob-expand-collapse-products .products-icon-text.collapsed .double-arrow-top,
	.mob-expand-collapse-variant .courses-icon-text.collapsed .double-arrow-top {
		transform: unset;
		transition: all 0.3s;
	}
    #accordion_cart_parent .collapse,
    #products_accordion_parent .collapse,
    #categories_accordion_parent .collapse,
    #variant_accordion_parent .collapse,
    .hide-for-mobile-view {
        display: none;
    }
    .mob-expand-collapse-cart,
    .mob-expand-collapse-categories,
    .mob-expand-collapse-products,
    .mob-expand-collapse-variant,
    #accordion_cart_parent .collapse.show,
    #products_accordion_parent .collapse.show,
    #variant_accordion_parent .collapse.show,
    #categories_accordion_parent .collapse.show {
        display: block;
    }
    .justify-content-between-center {
        justify-content: space-between;
    }
	@media (max-width: 992px) {
        .flex-sm-nowrap {
            flex-wrap: wrap !important;
            align-items: center;
            justify-content: center;
        }
        .w-80, .w-20 {
            width: 100%;
            justify-content: center;
        }
        .prodImage.prodImage-100px.prodImage-fixed > img {
            width: 100px;
            height: 100px;
            max-width: none;
        }
        .justify-content-between-center {
            justify-content: center;
        }
    }
    .infoCardVariants {
        border: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
    }
    .infoCardVariants .infoCardVariants-body {
        padding: 2rem 2.25rem;
        flex: 1 1 auto;
    }
    .accordionBlock {
        border: 1px solid #0d84f2;
        border-radius: 6px;
        position: relative;
    }
    .variantInfo {
        border: 0;
        box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
        background-color: #eff1f4;
        border-radius: 0.625rem;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        margin-top: 12px;
    }
</style>